<template>
  <div id="app">
    <PDD></PDD>
  </div>
</template>

<script>
import PDD from './components/CrowdfundingPage.vue'

export default {
  components: {
    PDD
  },
  methods: {}
}
</script>

<style>
html,
body {
  user-select: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
