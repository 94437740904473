import Vue from 'vue'
import App from './App.vue'
import i18n from './utils/lang/i18n'
import VueSeamlessScroll from 'vue-seamless-scroll'
import 'lib-flexible/flexible.js'


Vue.config.productionTip = false
Vue.use(VueSeamlessScroll);

new Vue({
  i18n,

  render: h => h(App),
}).$mount('#app')
