export default {
    AfriendPhone: 'Nambari ya simu ya rafiki:',

    wheelget: 'Bofya gurudumu linalozunguka kupata',
    Sign: 'TZS',
    takeout: 'Kuchukua',
    only: 'Unahitaji tu kupata nyingine',
    towithdraw: 'kutoa',
    Expira: 'Inaisha mnamo',
    Invite: 'Mwalike rafiki ili akusaidie kutoa',
    allbets: 'Bets Zote',
    Leaderboard: 'Ubao wa Wanaongoza',
    rank: 'Nafasi',
    user: 'Mtumiaji',
    betamount: 'Kiasi cha Beti',
    pddDesc: 'Sheria za Tukio',
    pddDesc1: '1. Kiasi cha jumla kinachofikia R$100 kinaweza kutolewa kwenye akaunti yako.',
    pddDesc2: '2. Waialike marafiki zako kusajili kwenye betgo.con, hii itakusaidia kutoa pesa haraka. Ikiwa rafiki yako atasajili na kucheza mchezo, itakusaidia kutoa pesa haraka zaidi.',
    pddDesc3: '3. Kila mtumiaji atapewa mzunguko mmoja bure kwa siku.',
    pddDesc4: '4. Kila mtumiaji anaweza kushiriki mara nyingi katika tukio ndani ya siku 3.',
    pddDesc5: '5. Ikiwa utapewa zawadi ya kiasi cha tukio, unaweza kucheza mchezo bure na kushinda pesa halisi zaidi.',
    pddDesc6: '6. Ikiwa pesa hazijatolewa ndani ya siku 3, pesa zitapotea.',
    GetSign: 'Pata TZS',
    frofree: ' bure',
    SACAR: 'SACAR',
    takewrong: 'kuchukua vibaya',


    FRIENDEARN: 'Msaada kwa Rafiki Kupata',
    Afterregistering: 'Baada ya kusajili, unaweza kushiriki katika shughuli kupata KSH',
    aftersign: 'Ingia ili kumsaidia apate',
    aftersign1: 'Baada ya kuingia, unaweza pia kushiriki katika tukio ili kupata.',
    invitedyou: 'amekualika kujiunga na tukio ili upate',
    helphim: 'Ingia ili kumsaidia kuongeza mapato',
    Helpfriend: 'Msaidia rafiki kupata',
    youfriend: 'Rafiki yako',
    pleasetry: 'Msaidia rafiki yako kupata',
}