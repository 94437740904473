<template>
  <div class="crowd">
    <div class="nav">
      <div class="nav_left">
        <img
          src="@/assets/images/common/pinduoduo_2.png"
          class="backImg"
          alt=""
        />
        <div>{{ topdesc1 }} {{ topAmount }}{{ topdesc2 }}</div>
      </div>
      <img
        src="@/assets/images/common/pinduoduo_1.png"
        class="questionImg"
        alt=""
      />
    </div>

    <div class="crowd_progress">
      <div class="crowd_progress_top">
        <img src="@/assets/images/common/pinduoduo_3.png" alt="" />
        <div>
          {{ this.$t('Sign') }}
          <transition name="fade"
            ><span>{{ amountObtained }}</span></transition
          >
        </div>
        <div class="crowd_progress_take" @click="takeOut()">
          {{ this.$t('takeout') }}
        </div>
      </div>
      <div class="crowd_progress_cen">
        <div class="progress_container">
          <div class="progress_bar">
            <div class="progress" :style="{ width: progress + '%' }"></div>
            <div
              class="progress_indicator"
              :style="{ left: progress + '%' }"
            ></div>
            <div class="progress_percentage" :style="{ left: progress + '%' }">
              <span>{{ progress + '%' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="crowd_progress_bottom">
        {{ this.$t('only') }}
        <span>{{ (topAmount - amountObtained).toFixed(2) }}</span>
        {{ this.$t('towithdraw') }}
      </div>
    </div>
    <div class="crowd_luckwheel">
      <div class="crowd_speaker">
        <div class="crowd_speaker_left">
          <img src="@/assets/images/common/pinduoduo_6.png" alt="" />
        </div>
        <div class="crowd_speaker_right">
          <div class="crowd_speaker_right_content">
            <div>{{ noticebar }}</div>
          </div>
        </div>
      </div>
      <div class="crowd_tigerwheel">
        <div class="crowd_bgcImg">
          <img
            src="https://src.999yes.com/mobileimages/tz/sw/crowdfunding/pinduoduo_8.png"
            alt=""
          />
        </div>

        <div
          class="crowd_tigerwheel_chassis"
          :style="{
            transform: rotateStyle
          }"
        >
          <img
            class="crowd_tigerwheel_img"
            src="@/assets/images/common/pinduoduo_34.png"
            alt=""
          />
          <div class="crowd_tigerwheel_propContext">
            <div class="propContext">
              <div
                class="propItem"
                v-for="item in propList"
                :key="item.Index"
                :id="item.Index"
                :style="{
                  transform: 'rotate(' + 45 * (item.Index - 1) + 'deg)'
                }"
              >
                <div v-if="item.IconType === 2" class="textspan">
                  {{ item.IconDesc }}
                </div>
                <div v-else>
                  <div
                    v-if="item.IconType === 1 && item.IconDesc == 'sacar'"
                    class="propItem_sacar"
                  >
                    <div>{{ SACAR }}</div>
                    <img src="@/assets/images/common/sacar.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.IconType == 1"
                    :class="'propItem_' + item.IconDesc"
                  >
                    <img
                      :src="getPath('common/' + item.IconDesc + '.png')"
                      :style="{
                        transform:
                          item.IconDesc == 'gold'
                            ? 'rotate(-' + 45 * (item.Index - 1) + 'deg)'
                            : ''
                      }"
                      alt=""
                    />
                  </div>
                  <div v-else-if="item.IconType === 3" class="propItem_money">
                    <img
                      src="@/assets/images/common/cash.png"
                      :style="{
                        transform: 'rotate(-' + 45 * (item.Index - 1) + 'deg)'
                      }"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="crowd_tigerwheel_light">
          <img src="@/assets/images/common/pinduoduo_11.png" alt="" />
          <img src="@/assets/images/common/pinduoduo_12.png" alt="" />
        </div>
        <div class="crowd_tigerwheel_toppoint">
          <img src="@/assets/images/common/pinduoduo_10.png" alt="" />
        </div>
        <div class="crowd_tigerwheel_point" @click="callBackForPlay()">
          <img src="@/assets/images/common/pinduoduo_9.png" alt="" />
          <div>{{ gametimes }}</div>
        </div>
        <div class="crowd_time">
          {{ this.$t('Expira') }}&nbsp; <span>{{ currentTime }}</span>
        </div>
        <div class="crowd_invite">
          <div @click="toggleMaskPop('invite')">
            <div>{{ this.$t('Invite') }}</div>
            <img src="@/assets/images/common/pinduoduo_31.png" alt="" />
          </div>
        </div>
      </div>
      <div class="crowd_rotate_img">
        <img src="@/assets/images/common/pinduoduo_7.png" alt="" />
      </div>
    </div>
    <div class="crowdlist">
      <div class="scroll_btn">
        <div :class="selectbtn ? 'select_btn' : ''" @click="selectbtn = true">
          {{ this.$t('allbets') }}
        </div>
        <div :class="!selectbtn ? 'select_btn' : ''" @click="selectbtn = false">
          {{ this.$t('Leaderboard') }}
        </div>
      </div>
      <div class="scroll_list">
        <div class="scroll_winner" v-show="selectbtn">
          <div class="scroll_auto_list">
            <vue-seamless-scroll :data="scrollAllBetsList">
              <div
                class="scroll_winner_list"
                v-for="(item, index) in scrollAllBetsList"
                :key="index"
              >
                <div class="scroll_winner_list_left">
                  <div>{{ item.number }}</div>
                  <!-- <div>{{ item.getType }}</div> -->
                </div>
                <div class="scroll_winner_list_center">
                  <div>Now</div>
                </div>
                <div class="scroll_winner_list_right">{{ item.amount }}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="scroll_ranking" v-show="!selectbtn">
          <div class="scroll_ranking_title">
            <div>{{ $t('rank') }}</div>
            <div>{{ $t('user') }}</div>
            <div>{{ $t('betamount') }}</div>
          </div>
          <div class="scroll_ranking_sroll">
            <div
              class="scroll_ranking_list"
              v-for="(item, index) in scrollLeaderBoardList"
              :key="index"
            >
              <div>{{ index + 1 }}th</div>
              <div class="scroll_ranking_list_number">
                <div><img :src="item.vipImg" alt="" /></div>
                <div>{{ item.number }}</div>
              </div>
              <div>{{ item.amount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="crowd_rules">
      <div class="crowd_title">
        <div>-------------</div>
        &nbsp;
        <div>{{ this.$t('pddDesc') }}</div>
        &nbsp;
        <div>-------------</div>
      </div>
      <div>
        <p>{{ this.$t('pddDesc1') }}</p>
        <p>{{ this.$t('pddDesc2') }}</p>
        <p>{{ this.$t('pddDesc3') }}</p>
        <p>{{ this.$t('pddDesc4') }}</p>
        <p>{{ this.$t('pddDesc5') }}</p>
        <p>{{ this.$t('pddDesc6') }}</p>
      </div>
    </div>

    <HELP_FRIEND></HELP_FRIEND>
  </div>
</template>
<script>
import HELP_FRIEND from './helpFriend.vue'
export default {
  components: {
    HELP_FRIEND
  },
  data() {
    return {
      currentTime: '00:00:00',
      topdesc1: this.$t('GetSign'),
      topAmount: 0, //总金额
      topdesc2: this.$t('frofree'),
      SACAR: this.$t('SACAR'),
      amountObtained: 0, //已经获取金额
      noticebar: 'Receive verification code by phone, TG, Whatsapp',
      rotateStyle: 0,
      propList: [
        { Index: 1, IconDesc: 'face', IconType: 1, IconUrl: 'face.icon' },
        { Index: 2, IconDesc: 'gold', IconType: 1, IconUrl: 'gold.icon' },
        { Index: 3, IconDesc: '1', IconType: 2, IconUrl: '' },
        { Index: 4, IconDesc: 'cash1', IconType: 3, IconUrl: 'cash1.icon' },
        { Index: 5, IconDesc: '100', IconType: 2, IconUrl: '' },
        { Index: 6, IconDesc: '1000', IconType: 2, IconUrl: '' },
        { Index: 7, IconDesc: 'sacar', IconType: 1, IconUrl: 'sacar.icon' },
        { Index: 8, IconDesc: 'cash2', IconType: 3, IconUrl: 'cash2.icon' }
      ],
      gametimes: 0,

      //滚动列表scroll_ranking
      selectbtn: true, //滚动列表选择按钮
      scrollAllBetsList: [], //押注排行列表
      scrollLeaderBoardList: [],
      progress: 97.86, // 进度百分比
      isFirst: true,
      countdownHours: 24 // 你的固定小时数
    }
  },
  methods: {
    startCountdown() {
      let remainingSeconds = this.countdownHours * 3600

      const intervalId = setInterval(() => {
        remainingSeconds--

        if (remainingSeconds <= 0) {
          clearInterval(intervalId)
          this.currentTime = '00:00:00'
        } else {
          const hours = Math.floor(remainingSeconds / 3600)
          const minutes = Math.floor((remainingSeconds % 3600) / 60)
          const seconds = remainingSeconds % 60
          this.currentTime = `${this.pad(hours)}:${this.pad(
            minutes
          )}:${this.pad(seconds)}`
        }
      }, 1000)
    },
    pad(number) {
      return (number < 10 ? '0' : '') + number
    },
    setscrollAllBetsList() {
      for (let i = 0; i < 19; i++) {
        const leftRandomPhone =
          '072' /* Math.floor(Math.random() * 900) + 1000 */
        const rightRandomPhone = Math.floor(Math.random() * 900) + 1000
        const starSign = '*****'
        const phone = leftRandomPhone + starSign + rightRandomPhone
        const randomNum = 100
        const obj = {
          number: phone,
          getType: 'Now',
          amount: randomNum
        }
        this.scrollAllBetsList.push(obj)
      }
    },
    getImg(path) {
      return require('@/assets/images/' + this.$i18n.locale + path)
    },
    getPath(path) {
      return require('@/assets/images/' + path)
    }
  },
  mounted() {
    this.setscrollAllBetsList()
    this.startCountdown()
  },
  destroyed() {}
}
</script>

<style lang="less" scoped>
.nav {
  height: 112px;
  background-color: #232433;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  color: #fff;
  font-size: 30px;
  .nav_left {
    display: flex;
    align-items: center;
    .backImg {
      width: 18px;
      height: 32px;
      margin-right: 30px;
    }
  }
  .questionImg {
    width: 41px;
    height: 41px;
  }
}
.crowd {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #272a3b;
  color: #fff;
  overflow-x: hidden;

  .crowd_getamount {
    margin-top: 10px;
    padding: 20px 80px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 2px;

    span {
      color: #ffe50e;
    }
  }

  .crowd_progress {
    width: 80%;
    background-color: #181924;
    margin: 0 auto;
    border-radius: 8px;
    padding: 20px 35px;

    .crowd_progress_top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #f3db0f;
      font-weight: bold;
      font-size: 50px;

      img {
        width: 70px;
        height: 55px;
      }

      .crowd_progress_take {
        background-color: #7e1af1;
        color: #fff;
        width: 160px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 12px;
        font-size: 24px;
        font-weight: normal;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: all 0.5s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
    }

    .crowd_progress_cen {
      height: 60px;
      display: flex;
      flex-direction: column-reverse;

      .progress_container {
        width: 100%;
        padding: 0 4px;
        height: 26px;
        background-color: #363849;
        /* 进度条容器的背景色 */
        border-radius: 10px;
        /* 圆角 */
      }

      .progress_bar {
        height: 18px;
        border-radius: 10px;
        /* 圆角 */
        position: relative;
        margin-top: 4px;
      }

      .progress {
        height: 95%;
        background: linear-gradient(to right, #007dfe, #00a0fe);
        /* 进度条渐变色 */
        border-radius: 10px;
        /* 圆角 */
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s left;
      }

      .progress_indicator {
        position: absolute;
        top: 0;
        width: 22px;
        height: 22px;
        background-color: #ffffff;
        border-radius: 50%;
        /* 圆形 */
        transform: translate(-50%, -10%);
        transition: 0.1s left;
      }

      .progress_percentage {
        position: absolute;
        top: -44px;
        transform: translateX(-50%);
        background: url('@/assets/images/common/pinduoduo_4.png') no-repeat;
        background-size: 100%;
        width: 100px;
        height: 35px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        transition: 0.1s left;
      }
    }

    .crowd_progress_bottom {
      text-align: center;
      margin-top: 10px;

      span {
        color: #ffe50e;
        font-size: 28px;
      }
    }
  }

  .crowd_luckwheel {
    width: 100%;
    height: 880px;

    .crowd_rotate_img {
      position: relative;
      top: -950px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;

      img {
        width: 1000px;
        height: 1000px;
        animation: rotate 10s linear infinite;
        /* 10秒钟完成一次旋转，线性速度，无限循环 */
      }
    }

    /*  background: url('@/assets/images/common/pinduoduo_7.png')
      no-repeat;
    background-size: 100%; */
    .crowd_speaker {
      width: 78%;
      background: url('@/assets/images/common/pinduoduo_5.png') no-repeat;
      background-size: 100%;
      margin: 0 auto;
      position: relative;
      top: 15px;
      height: 45px;

      .crowd_speaker_left {
        width: 80px;
        height: 45px;
        position: absolute;
        left: 40px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 25px;
          height: 20px;
          margin-bottom: 3px;
          position: absolute;
        }
      }

      .crowd_speaker_right {
        font-size: 16px;
        margin-left: 110px;
        overflow: hidden;

        .crowd_speaker_right_content {
          color: #fff;
          height: 40px;
          line-height: 40px;
          margin-left: 100%;
          /*  // 把文字弄出可见区域 */
          width: 200%;
          animation: textMove 10s linear infinite;
          /*  // 重点，定义动画 */
          animation-fill-mode: forwards;
          font-size: 22px;
        }
      }
    }

    .crowd_tigerwheel {
      margin: 0 auto;
      margin-top: 6px;
      width: 85%;
      height: 100%;
      position: relative;
      z-index: 1;

      .crowd_bgcImg {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 600px;
          height: 710px;
        }
      }

      .crowd_tigerwheel_chassis {
        position: relative;
        top: -595px;
        left: -45px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        width: 100%;
        overflow: hidden;

        .crowd_tigerwheel_img {
          width: 400px;
          height: 400px;
          position: absolute;
          top: -0;
        }

        .crowd_tigerwheel_propContext {
          position: absolute;
          top: 10px;

          .propContext {
            width: 390px;
            height: 390px;
            transform: rotate(90deg);

            .propItem {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              height: 130px;
              display: flex;
              align-items: center;
              padding-left: 18px;

              > div {
                rotate: -90deg;
              }

              .textspan {
                font-size: 38px;
                color: #ffd760;
                font-weight: bold;
                display: flex;
                justify-content: center;
                rotate: -90deg;
                padding-top: 10px;
              }

              .propItem_face {
                img {
                  width: 50px;
                  height: 50px;
                }
              }

              .propItem_gold {
                img {
                  width: 62px;
                  height: 51px;
                }
              }

              .propItem_money {
                img {
                  width: 65px;
                  height: 52px;
                }
              }

              .propItem_sacar {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                  width: 40px;
                  height: 42px;
                  rotate: 90deg;
                  position: relative;
                  top: 10px;
                }
              }

              .shadowpurple {
                text-shadow: 1px 1px 0px #b712c3, -1px -1px 0px #b712c3,
                  2px 2px 0px #b712c3, -2px -2px 0px #b712c3,
                  3px 3px 0px #b712c3, -3px -3px 0px #b712c3;
                text-indent: 1em;
              }

              .shadowwhite {
                text-shadow: -2px 2px 0 #000;
              }
            }
          }
        }
      }

      .crowd_tigerwheel_light {
        position: relative;
        top: -1020px;
        left: -45px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 455px;
          height: 455px;
          position: absolute;
          top: 0;
        }

        :nth-child(1) {
          animation: light 0.5s infinite;
        }

        :nth-child(2) {
          animation: dark 0.5s infinite;
        }
      }

      .crowd_tigerwheel_point {
        position: relative;
        top: -970px;
        left: -25px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        img {
          width: 150px;
          height: 190px;
          position: absolute;
          top: 0;
          margin-right: 40px;
        }

        div {
          margin-top: 55px;
          margin-right: 8px;
          position: absolute;
          top: 0;
          padding-right: 33px;
          font-size: 80px;
          font-weight: bold;
          text-shadow: 1px 1px 0px #000, -1px -1px 0px #000, 2px 2px 0px #000,
            -2px -2px 0px #000, 3px 3px 0px #000, -3px -3px 0px #000;
        }
      }

      .crowd_tigerwheel_toppoint {
        position: relative;
        top: -1020px;
        left: -45px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        img {
          width: 110px;
          height: 65px;
        }
      }

      .crowd_time {
        position: relative;
        top: -600px;
        left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 32px;
        color: #b1b0cd;

        span {
          color: #f3e514;
        }
      }

      .crowd_invite {
        width: 100%;
        position: relative;
        top: -590px;
        left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
          width: 500px;
          height: 70px;
          background-color: #108f34;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 40px;
          border-radius: 12px;
          margin: 0 auto;
        }

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .crowdlist {
    display: flex;
    flex-direction: column;
    padding: 0 3%;
    margin-top: 55px;
    position: relative;

    .scroll_btn {
      width: 550px;
      height: 70px;
      line-height: 69px;
      display: flex;
      justify-content: center;
      background-color: #232433;
      font-size: 20px;
      color: #bbc4dd;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 6px;
      border-radius: 12px;

      > div {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 12px;
      }

      .select_btn {
        color: #fff;
        border-radius: 12px;
        background-color: #393b47;
      }
    }

    .scroll_list {
      margin-bottom: 20px;

      .scroll_winner {
        color: #bbc4dd;
        width: 100%;

        .scroll_winner_list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 70px;
          padding: 0 20px;
          border-radius: 12px;
          font-size: 26px;

          .scroll_winner_list_left {
            vertical-align: middle;
            display: flex;

            img {
              width: 19px;
              height: 21px;
              margin: 0 5px 3px 0;
            }
          }

          .scroll_winner_list_right {
            color: #1bc841;
          }
        }

        .scroll_auto_list {
          height: 430px;
          overflow: hidden;
          background-color: #232433;
          border-radius: 12px;
        }
      }

      .scroll_ranking {
        color: #bbc4dd;
        width: 100%;
        .scroll_ranking_title {
          display: flex;
          justify-content: space-between;
          margin: 20px 0;

          div:nth-child(2) {
            margin-right: 20px;
          }
        }
        .scroll_ranking_sroll {
          min-height: 270px;
          max-height: 900px;
          overflow: auto;
        }
        .scroll_ranking_list {
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          border-radius: 12px;

          img {
            width: 29px;
            height: 33px;
          }

          .scroll_ranking_list_number {
            margin-right: 50px;
            display: flex;

            img {
              width: 60px;
              height: 30px;
            }
          }

          div:nth-child(3) {
            color: #1bc841;
          }
        }

        .scroll_ranking_list:nth-child(2n) {
          background-color: #212230;
          /* 偶数行背景色 */
        }

        .scroll_ranking_list:nth-child(2n + 1) {
          background-color: #272939;
          /* 奇数行背景色 */
        }
      }
    }
  }

  .crowd_rules {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6b6f88;
    font-size: 24px;
    margin-bottom: 150px;

    p {
      margin-bottom: 10px;
    }

    .crowd_title {
      display: flex;
      color: #4f81db;
      align-items: center;
      margin-bottom: 30px;
      font-size: 30px;
    }
  }
}

@keyframes textMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-3000px);
  }
}

@keyframes light {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dark {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
